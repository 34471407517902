import React from "react"
import cx from "classnames"

import { Container } from "@ui/Container"
import { Row } from "@ui/Row"
import TrianglesFirst from "@icons/home/HomeDesignBlock5_1.svg"
import TrianglesSecond from "@icons/home/HomeDesignBlock5_3.svg"

import * as s from "./ServiceScreen.module.sass"

type ServiceScreenProps = {
  header: string
  description: string
  className?: string
}

export const ServiceScreen: React.FC<ServiceScreenProps> = ({
  header,
  description,
  className,
}) => {
  return (
    <section className={cx(s.root, className)}>
      <TrianglesFirst className={s.triangleFirst} />
      <TrianglesSecond className={s.triangleSecond} />
      <Container theme="middle">
        <Row className={s.row}>
          <div className={s.content}>
            <h2 className={s.header}>{header}</h2>
            <p className={s.description}>{description}</p>
          </div>
        </Row>
      </Container>
    </section>
  )
}
