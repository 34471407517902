import React, { ReactNode } from "react"
import cx from "classnames"

import { Container } from "@ui/Container"
import { Row } from "@ui/Row"

import * as s from "./RepareScreen.module.sass"

type RepareScreenProps = {
  header: string
  description: string
  header2: string
  description2: string
  className?: string
}

export const RepareScreen: React.FC<RepareScreenProps> = ({
  header,
  description,
  header2,
  description2,
  className,
}) => {
  return (
    <section className={cx(s.root, className)}>
      <Container theme="middle">
        <Row className={s.row}>
          <div className={s.content}>
            <h2 className={s.header}>{header}</h2>
            <p className={s.description}>{description}</p>
          </div>
          <div className={cx(s.content, s.right)}>
            <h2 className={s.header}>{header2}</h2>
            <p className={s.description}>{description2}</p>
          </div>
        </Row>
      </Container>
    </section>
  )
}
