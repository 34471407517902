import React from "react"
import cx from "classnames"
import parse from "html-react-parser"

import { Container } from "@ui/Container"
import { Row } from "@ui/Row"

import * as s from "./ContentScreen.module.sass"

type ContentScreenProps = {
  className?: string
}

export const ContentScreen: React.FC<ContentScreenProps> = ({
  children,
  className,
}) => {
  return (
    <div className={cx(s.root, className)}>
      <Container theme="middle">
        <Row className={s.row}>{parse(children as string)}</Row>
      </Container>
    </div>
  )
}
