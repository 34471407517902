import React from "react"
import cx from "classnames"

import * as s from "./ServicesItem.module.sass"

import Background1 from "@icons/home/HomeServices1.svg"
import Background2 from "@icons/home/HomeServices2.svg"
import Background3 from "@icons/home/HomeServices3.svg"
import Background4 from "@icons/home/HomeServices4.svg"
import Background5 from "@icons/home/HomeServices5.svg"
import Background6 from "@icons/home/HomeServices6.svg"

type ServicesItemProps = {
  index: number
  className?: string
}

export const ServicesItem: React.FC<ServicesItemProps> = ({
  index,
  children,
  className,
}) => (
  <div className={cx(s.root, className)}>
    {index % 6 === 0 && <Background1 className={s.background} />}
    {index % 6 === 1 && <Background2 className={s.background} />}
    {index % 6 === 2 && <Background3 className={s.background} />}
    {index % 6 === 3 && <Background4 className={s.background} />}
    {index % 6 === 4 && <Background5 className={s.background} />}
    {index % 6 === 5 && <Background6 className={s.background} />}
    {children}
  </div>
)
