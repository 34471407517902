import React from "react"
import cx from "classnames"

import { Container } from "@ui/Container"
import { Row } from "@ui/Row"
import Triangles from "@icons/home/HomeDesignBlock2.svg"

import * as s from "./BusinessScreen.module.sass"

type BusinessScreenProps = {
  header: string
  description: string
  className?: string
}

export const BusinessScreen: React.FC<BusinessScreenProps> = ({
  header,
  description,
  className,
}) => {
  return (
    <section className={cx(s.root, className)}>
      <Triangles className={s.triangle} />
      <Container theme="middle">
        <Row className={s.row}>
          <h2 className={s.header}>{header}</h2>
          <p className={s.description}>{description}</p>
        </Row>
      </Container>
    </section>
  )
}
