import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import cx from "classnames"

import { Container } from "@ui/Container"
import { Row } from "@ui/Row"
import TrianglesFirst from "@icons/home/HomeDesignBlock3_1.svg"
import TrianglesSecond from "@icons/home/HomeDesignBlock3_2.svg"

import * as s from "./FridgeScreen.module.sass"

type FridgeScreenProps = {
  header: string
  description: string
  className?: string
}

export const FridgeScreen: React.FC<FridgeScreenProps> = ({
  header,
  description,
  className,
}) => {
  return (
    <section className={cx(s.root, className)}>
      <div className={s.image}>
        <StaticImage
          src="../../../images/HomePage-Third.jpg"
          alt={header}
          layout="fullWidth"
          style={{ height: "100%" }}
        />
      </div>
      <TrianglesFirst className={s.triangleFirst} />
      <TrianglesSecond className={s.triangleSecond} />
      <Container theme="middle">
        <Row className={s.row}>
          <h2 className={s.header}>{header}</h2>
          <p className={s.description}>{description}</p>
        </Row>
      </Container>
    </section>
  )
}
