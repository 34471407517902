import React from "react"
import cx from "classnames"

import { Container } from "@ui/Container"
import { Row } from "@ui/Row"
import { ServicesItem } from "@components/home/ServicesScreen/ServicesItem"

import * as s from "./ServicesScreen.module.sass"

type ServicesScreenProps = {
  header: string
  description: string
  services: {
    translations: {
      description: string
    }[]
  }[]
  className?: string
}

export const ServicesScreen: React.FC<ServicesScreenProps> = ({
  header,
  description,
  services,
  className,
}) => {
  return (
    <section className={cx(s.root, className)}>
      <Container theme="middle">
        <Row className={s.row}>
          <h2 className={s.header}>{header}</h2>
          <p className={s.description}>{description}</p>
          <div className={s.wrapper}>
            {services.map((service, index) => (
              <ServicesItem
                key={service.translations[0].description}
                index={index}
              >
                {service.translations[0].description}
              </ServicesItem>
            ))}
          </div>
        </Row>
      </Container>
    </section>
  )
}
