import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import cx from "classnames"

import { Container } from "@ui/Container"
import { Row } from "@ui/Row"
import { LinkToForm } from "@components/common/LinkToForm"
import Triangles from "@icons/home/HomeDesignBlock1.svg"

import * as s from "./FirstScreen.module.sass"

type FirstScreenProps = {
  header: string
  className?: string
}

export const FirstScreen: React.FC<FirstScreenProps> = ({
  header,
  className,
}) => {
  return (
    <section className={cx(s.root, className)}>
      <div className={s.imageWrapper}>
        <div className={s.image}>
          <div className={s.imageInner}>
            <StaticImage
              src="../../../images/HomePage-First.jpg"
              alt={header}
              layout="fullWidth"
              style={{ height: "100%" }}
            />
          </div>
        </div>
      </div>
      <Triangles className={s.triangle} />
      <Container theme="middle">
        <Row className={s.row}>
          <LinkToForm className={s.link} withRhombus={true} />
          <h1 className={s.header}>{header}</h1>
        </Row>
      </Container>
    </section>
  )
}
